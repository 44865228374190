exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktiviteter-js": () => import("./../../../src/pages/aktiviteter.js" /* webpackChunkName: "component---src-pages-aktiviteter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kart-js": () => import("./../../../src/pages/kart/[...].js" /* webpackChunkName: "component---src-pages-kart-js" */),
  "component---src-templates-activity-js": () => import("./../../../src/templates/activity.js" /* webpackChunkName: "component---src-templates-activity-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

